
export function UserForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='username'>Nom Prénom(s)</label>
                        <input className='input w-full border flex-1 bg-white' type='text'
                        id='username' name='username' placeholder='Nom Prénom(s)' 
                        value={props.useUser.username ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.useUser.setUsername(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <input className='input w-full border flex-1 bg-white' 
                        type='text' id='email' name='email' placeholder='Email' 
                        value={props.useUser.email ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.useUser.setEmail(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='email'>Numéro de téléphone</label>
                        <input className='input w-full border flex-1 bg-white' 
                        type='tel' id='tel' name='tel' placeholder='Numéro de téléphone' 
                        value={props.useUser.numero_telephone ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.useUser.setNumero_telephone(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='birthdate'>Date de naissance</label>
                        <input className='input w-full border flex-1 bg-white' type='date' 
                        id='birthdate' name='birthdate' placeholder='Date de naissance' 
                        value={props.useUser.birthdate ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.useUser.setBirthdate(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='address'>Addresse</label>
                        <input className='input w-full border flex-1 bg-white' 
                        type='text' id='address' name='address' placeholder='Addresse' 
                        value={props.useUser.address ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.useUser.setAddress(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='mt-4 flex justify-end intro-y'>
                    <button disabled={props.isDisabled ?? false} type='submit' 
                    className='button bg-theme-1 text-white px-5' onClick={props.handleFormSubmit}>
                        {props.isDisabled ? "Chargement..." : "Enregistrer"}
                    </button>
                </div>
            </div>
        </form>
    )
}