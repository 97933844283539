import { useState} from "react";
import PhoneInput from "react-phone-input-2";

export function Bureau(props) {
  const formContainer = "gerants-form-container";

  // const [nombreGerant, setNombreGerant] = useState("");
  const [gerant, setGerant] =useState(Object.keys(props.bureau).length === 0?{}:JSON.parse(props.bureau));
      //useState(JSON.parse(props.bureau??'{}'));
  const [gerantType, setGerantType] = useState(Object.keys(props?.bureau).length === 0?'non':'oui');
  const [presidentType, setPresidentType] = useState(Object.keys(props?.president).length === 0?'non':'oui');
  const [secretaireType, setSecretaireType] = useState(Object.keys(props?.secretaire).length === 0?'non':'oui');
  const [tresorierType, setTresorierType] = useState(Object.keys(props?.tresorier).length === 0?'non':'oui');

  const handleInputChange = (name, value) => {
    const gerantsCopy = { ...gerant };
    gerantsCopy[name] = value;
    // const gerantsCopy = gerants.map(gerrant => {
    //     return {...gerrant};
    // });
    // gerantsCopy[index][name] = value;

    setGerant(gerantsCopy);
  };
  const handleTypeGerantChange = (value) => {
    setGerant({});

    if(value==='oui'){
      setPresidentType("");
      setSecretaireType("");
      setTresorierType("");
      const formContainerElem = window.document.querySelector(
        `#${formContainer}-0`
      );
      Array.from(formContainerElem.querySelectorAll(".field")).forEach(
        (field) => (field.value = "")
      );
    }

    setGerantType(value);
  };
  const handleTypePresidentChange = (value) => {

    // const formContainerElem = window.document.querySelector(
    //   `#${formContainer}-1`
    // );
    // Array.from(formContainerElem.querySelectorAll(".field")).forEach(
    //   (field) => (field.value = "")
    // );

    setPresidentType(value);
  };
  const handleTypeSecretaireChange = (value) => {

    // const formContainerElem = window.document.querySelector(
    //   `#${formContainer}-2`
    // );
    // Array.from(formContainerElem.querySelectorAll(".field")).forEach(
    //   (field) => (field.value = "")
    // );

    setSecretaireType(value);
  };

  const handleTypeTresorierChange = (value) => {

    // const formContainerElem = window.document.querySelector(
    //   `#${formContainer}-3`
    // );
    // Array.from(formContainerElem.querySelectorAll(".field")).forEach(
    //   (field) => (field.value = "")
    // );

    setTresorierType(value);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    const gerantsCopy = { ...gerant };
    setGerant(gerantsCopy);

    if (!gerant) return alert("Vous devez remplir le formulaire !");
    props.handleNextClick(e, "bureau", JSON.stringify(gerantsCopy));
  };


  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <h4 className="intro-y col-span-12 text-lg leading-none">
        Y a-t-il des membres facultatifs du Bureau Exécutif ?
      </h4>

      <div className="intro-y col-span-12" id={`${formContainer}-0`}>
        {/* <small className="mb-4">Indiquez les informations relatives au gérant </small> */}

        
        <div className="flex flex-col sm:flex-row mt-4 mb-2">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0-membres`}
              name={`gerant-0-v`}
              value="oui"
              checked={gerantType==='oui'}
              onChange={(e) => handleTypeGerantChange("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0-membres`}
            >
              Oui
            </label>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0-membres`}
              name={`gerant-0-v`}
              value="non"
              checked={gerantType==='non'}
              onChange={(e) => handleTypeGerantChange("non")}
            />
            <label className="cursor-pointer select-none" htmlFor={`horizontal-non-0-membres`}>
              Non
            </label>
          </div>
        </div>
        <div
          className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerantType !== undefined && gerantType === "oui"
                            ? ""
                            : "hidden"
                        }`}
        >
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className={`mb-2`}>Avez-vous un vice-président ?</div>
            <div className="flex flex-col sm:flex-row mt-4 mb-2">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-radio-oui-0-vice-président`}
                  name={`president-0`}
                  value="oui"
                  checked={presidentType==='oui'}
                  onChange={(e) => handleTypePresidentChange("oui")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-oui-0-vice-président`}
                >
                  Oui
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-non-0-vice-président`}
                  name={`president-0`}
                  value="non"
                  checked={presidentType==='non'}
                  onChange={(e) => handleTypePresidentChange("non")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-non-0-vice-président`}
                >
                  Non
                </label>
              </div>
            </div>

            <div
              className={`
                        ${
                          presidentType !== undefined && presidentType === "oui"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="mb-2">Nom & prénom(s) du vice-président</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nom & prénom(s)"
                value={gerant['nom-vice-president']}
                onChange={(e) =>
                  handleInputChange("nom-vice-president", e.target.value)
                }
              />
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nationalité du vice-président</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nationalité"
                  value={gerant['nationalite-vice-president']}
                  onChange={(e) =>
                    handleInputChange(
                      "nationalite-vice-president",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Numéro de téléphone du vice-président{" "}
                </div>
                <PhoneInput
                    country={"ci"}
                    // value={this.e.target}
                    value={gerant['numero-vice-president']}
                    onChange={(e) =>
                        handleInputChange( 'numero-vice-president', e)
                    }
                    autoFormat={false}
                    inputStyle={{
                      padding: "21px1 20px 21px 52px",
                      width: "100%",
                      boxShadow: "0px 3px 5px #00000007",
                      borderColor: "rgba(226, 232, 240, 1)",
                    }}
                    placeholder="Numéro de téléphone"
                />

{/*                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone"
                  value={gerant['numero-vice-president']}
                  onChange={(e) =>
                    handleInputChange("numero-vice-president", e.target.value)
                  }
                />*/}
              </div>
            </div>
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className={`mb-2`}>
              Avez-vous un secrétaire général adjoint ?
            </div>
            <div className="flex flex-col sm:flex-row mt-4 mb-2">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-radio-oui-2-secretaire-general`}
                  name={`secretaire-2`}
                  value="oui"
                  checked={secretaireType==='oui'}
                  onChange={(e) => handleTypeSecretaireChange("oui")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-oui-2-secretaire-general`}
                >
                  Oui
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-non-2-secretaire-general`}
                  name={`secretaire-2`}
                  value="non"
                  checked={secretaireType==='non'}
                  onChange={(e) => handleTypeSecretaireChange("non")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-non-2-secretaire-general`}
                >
                  Non
                </label>
              </div>
            </div>

            <div
              className={`
                        ${
                          secretaireType !== undefined &&
                          secretaireType === "oui"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="mb-2">Nom & prénom(s) du SG adjoint</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nom & prénom(s)"
                value={gerant['nom-secretaire-adjoint']}
                onChange={(e) =>
                  handleInputChange("nom-secretaire-adjoint", e.target.value)
                }
              />
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nationalité du SG adjoint</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nationalité"
                  value={gerant['nationalite-secretaire-adjoint']}
                  onChange={(e) =>
                    handleInputChange(
                      "nationalite-secretaire-adjoint",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de téléphone du SG adjoint </div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone"
                  value={gerant['numero-secretaire-adjoint']}
                  onChange={(e) =>
                    handleInputChange(
                      "numero-secretaire-adjoint",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className={`mb-2`}>
              Avez-vous un trésorier général adjoint ?
            </div>
            <div className="flex flex-col sm:flex-row mt-4 mb-2">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-radio-oui-3-tresorier-general`}
                  name={`gerant-3`}
                  value="oui"
                  checked={tresorierType==='oui'}
                  onChange={(e) => handleTypeTresorierChange("oui")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-oui-3-tresorier-general`}
                >
                  Oui
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-non-3-tresorier-general`}
                  name={`gerant-3`}
                  value="non"
                  checked={tresorierType==='non'}
                  onChange={(e) => handleTypeTresorierChange("non")}
                />
                <label className="cursor-pointer select-none" htmlFor={`horizontal-non-3-tresorier-general`}>
                  Non
                </label>
              </div>
            </div>

            <div
              className={`
                        ${
                          tresorierType !== undefined &&
                          tresorierType === "oui"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="mb-2">
                Nom & prénom(s) du trésorier général adjoint
              </div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nom & prénom(s)"
                value={gerant['nom-tresorier-adjoint']}
                onChange={(e) =>
                  handleInputChange("nom-tresorier-adjoint", e.target.value)
                }
              />
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Nationalité du trésorier général adjoint
                </div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nationalité"
                  value={gerant['nationalite-tresorier-adjoint']}
                  onChange={(e) =>
                    handleInputChange(
                      "nationalite-tresorier-adjoint",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Numéro de téléphone du trésorier général adjoint{" "}
                </div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone"
                  value={gerant['numero-tresorier-adjoint']}
                  onChange={(e) =>
                    handleInputChange(
                      "numero-tresorier-adjoint",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
