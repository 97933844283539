import { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import * as Icons from 'react-feather';
import PhoneInput from "react-phone-input-2";


export function PresidentOng(props) {
  const formContainer = "associes-form-container";
  // const nombreAssocies =
  //   props.nombreAssocies && Number.isInteger(parseInt(props.nombreAssocies))
  //     ? parseInt(props.nombreAssocies)
  //     : 0;
  const [associes, setAssocies] = useState(() => {
    const presidentData = props.president || "{}"; // Si props.president est undefined, utilise "{}"
    return typeof presidentData === 'string' ? JSON.parse(presidentData) : presidentData;   // Parse les donnée présidentielles
  });


  const [typePresident, setTypePresident] = useState(associes["type-president"]);

  const handleTypeAssocieChange = (value) => {

    setTypePresident(value)

  };
  const libelle = props?.demande || "";
  const handleInputChange = (name, value) => {
    const associesCopy = { ...associes };
    associesCopy[name] = value;

    setAssocies(associesCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    const associesCopy = { ...associes };
    associesCopy["type-president"] = typePresident;
    props.handleNextClick(e, "president", JSON.stringify(associesCopy));

  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >

      <div className="intro-y col-span-12  ">

        <strong> {libelle ? `Qui est le Président du comité de gestion du ${libelle} ?` : "Qui est le Président du Bureau Exécutif ?"}
        </strong>

      </div>
      <div className="intro-y col-span-12  ">

        <Alert variant='danger'>
          <Alert.Heading> <Icons.Info className="w-4 h-4 " />
          </Alert.Heading> Renseignez les informations personnelles du Président du Bureau Exécutif. Si vous n’avez pas certaines
          informations, vous pourrez compléter plus tard
        </Alert>

      </div>

      {/* <h4 className="intro-y col-span-12 text-lg leading-none">
        <small>
          ().
        </small>
      </h4> */}


      <div className="intro-y col-span-12">

        <div className="intro-y col-span-12" id={`${formContainer}`}>
          <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">

            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">

              <input type="radio" className="overlay opacity-0"
                id={`horizontal-radio-particulier-presi-${0}`}
                name={`associe-president-${0}`} value="particulier"
                checked={associes["type-president"] === 'particulier'}
                onChange={e => handleTypeAssocieChange("particulier")} />
              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>

              <div className="ml-2 mr-auto">
                <div className="font-medium">Un particulier</div>
              </div>


            </div>

          </div>
          <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${(typePresident !== undefined && (typePresident === 'moi-meme' ||
              typePresident === 'particulier')) ? '' : 'hidden'}`}>

            {typePresident === 'particulier' ?
              <>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Nom</div>
                  <input type="text" value={associes['nom']} className="input w-full border flex-1 field"
                    placeholder="Nom" onChange={e => handleInputChange(
                      'nom', e.target.value)} />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Prénom(s)</div>
                  <input type="text" value={associes['prenoms']} className="input w-full border flex-1 field"
                    placeholder="Prénom(s)" onChange={e => handleInputChange(
                      'prenoms', e.target.value)} />
                </div>
                <div className="intro-y col-span-6 sm:col-span-6">
                  <div className="mb-2">Numéro de téléphone </div>
                  <PhoneInput
                    country={"ci"}
                    // value={e.target.value
                    onChange={(e) =>
                      handleInputChange("numero-telephone", e)
                    }
                    autoFormat={false}
                    inputStyle={{
                      padding: "21px1 20px 21px 52px",
                      width: "100%",
                      boxShadow: "0px 3px 5px #00000007",
                      borderColor: "rgba(226, 232, 240, 1)",
                    }}
                    placeholder="Numéro de téléphone"
                  />
                </div>
              </>
              : null}
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nationalité du président</div>
              <input type="text" value={associes['nationalite']} className="input w-full border flex-1 field"
                placeholder="Nationalité du présiden" onChange={e => handleInputChange(
                  'nationalite', e.target.value)} />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nombre de part detenue</div>
              <input
                type="number"
                className="input w-full border flex-1"
                placeholder="Nombre de part detenue"
                onChange={(e) =>
                  handleInputChange("Nombre de part detenue", e.target.value)
                }
              />
            </div>

          </div>

        </div>

      </div>

      {/* <input
        type="number"
        className="input w-full border flex-1"
        placeholder="Apport de l'associé"
        value={associe["apport"] ?? ""}
        onChange={(e) =>
          handleInputChange(index, "apport", e.target.value)
        }
      /> */}
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
