import { useState } from "react";


// import 'bootstrap/dist/css/bootstrap.min.css';

export function FacturesRecouvre(props) {
  const formContainer = "sigle-form-container";

  const [sigle, setSigle] = useState("");
  const [sigleType, setSigleType] = useState("non");

  const handleSigleChange = (value) => {
    if (value === "non") {
      const formContainerElem = window.document.querySelector(
        `#${formContainer}`
      );

      Array.from(formContainerElem.querySelectorAll(".field")).forEach(
        (field) => (field.value = "")
      );

      setSigle("");
    }

    setSigleType(value);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    if (sigleType === "non" || sigle) {
      props.handleNextClick(e, "nombre-facture", sigle);
    } else {
      return alert("Vous devez remplir le formulaire !");
    }
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
      id={`${formContainer}`}
    >
      <h4 className="intro-y col-span-12 text-lg leading-none">
        Avez vous en votre possession des factures liées à cette dette ?
      </h4>
      <div className="intro-y col-span-12 ">
        <div className="flex flex-col sm:flex-row mt-4 mb-2">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0`}
              name={`sigle-0`}
              value="oui"
              onChange={(e) => handleSigleChange("non")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-non-0`}
            >
              Non
            </label>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0`}
              name={`sigle-0`}
              value="non"
              onChange={(e) => handleSigleChange("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0`}
            >
              Oui
            </label>
          </div>
        </div>
        <input
          type="number"
          className={`input w-full border flex-1 field ${
            sigleType === "non" ? "hidden" : " "
          }`}
          placeholder="De combien de factures disposez vous ?"
          disabled={(!sigle && sigleType === "") || sigleType === "non"}
          onChange={(e) => setSigle(e.target.value)}
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
