import { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import * as Icons from 'react-feather';
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";

export function AssociesMa(props) {
  const formContainer = "associes-form-container";
  const nombreAssocies =
    props.nombreAssocies && Number.isInteger(parseInt(props.nombreAssocies))
      ? parseInt(props.nombreAssocies) : 0;
  const [associes, setAssocies] = useState(props.associes);
  const [isMeme, setIsMeme] = useState(100);

  const handleTypeAssocieChange = (index, value) => {
    const associesCopy = associes.map((associe) => {
      return { ...associe };
    });
    associesCopy[index] = {};
    associesCopy[index]["type"] = value;

    const formContainerElem = window.document.querySelector(
      `#${formContainer}-${index}`
    );

    Array.from(formContainerElem.querySelectorAll(".field"))
    .forEach((field) => (field.value = ""));

    if(value==='moi-meme'){

        associesCopy[index]["nom"] = associes[index]["nom"]; // Gets the first part
        associesCopy[index]["prenoms"] = associes[index]["prenoms"];

        associesCopy[index]["numero-telephone"] = associes[index]["numero-telephone"];
      setIsMeme(index)
      for (let i = 0; i < nombreAssocies; i++) {
        if(i!==index ){
            window.document.querySelector(
              `#${formContainer}-${i}`
            );
        }
      }
    }else{

      if(isMeme===index){
        setIsMeme(100)
        
        for (let i = 0; i < nombreAssocies; i++) {

        window.document.querySelector(
          `#${formContainer}-${i}`
        );
        }

      }

    } 

    setAssocies(associesCopy);
  };
  const handleInputChange = (index, name, value) => {
    const associesCopy = associes.map((associe) => {
      return { ...associe };
    });
    associesCopy[index][name] = value;

    setAssocies(associesCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

      for(let index = 0; index < associes.length; index++) {
          let associate = associes[index];

          if(associate["date-naissance"]==="" || associate['date-naissance']===undefined) {
              associate['date-naissance']=moment().format('YYYY-MM-DD');
          }
      }


    props.handleNextClick(e, "associes", JSON.stringify(associes));
  };

  useEffect(() => {
    if (!nombreAssocies) return;
   // setAssocies(Array(nombreAssocies).fill({}));
  }, [nombreAssocies]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >

<div className="intro-y col-span-12  ">
                
                <strong>Qui sont les associés de la société ?
</strong>  
            
        </div>
        <div className="intro-y col-span-12  ">
          
            <Alert  variant='danger'>
            <Alert.Heading>                                            <Icons.Info className="w-4 h-4 " />
</Alert.Heading>

 

Renseignez les informations personnelles de <Alert.Link href="#"> chaque associé </Alert.Link> (y compris
          vous-mêmes si vous êtes associé). Si vous n’avez pas certaines
          informations, vous pourrez compléter plus tard


            </Alert>
           
        </div>
         
      {/* <h4 className="intro-y col-span-12 text-lg leading-none">
        <small>
          ().
        </small>
      </h4> */}
      {associes.map((associe, index) => {
        return (
          <div className="intro-y col-span-12" key={index}>

<Accordion defaultActiveKey="0">
      <Accordion.Item eventKey={index}>
        <Accordion.Header> ASSOCIÉ (NUMÉRO {index + 1})
        <br/>
        Indiquez les informations relatives à l'associé {index + 1}  

        </Accordion.Header>
        <Accordion.Body>
        <div className="intro-y col-span-12" key={index} id={`${formContainer}-${index}`}>
               <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">

            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
           
                <input type="radio" className="overlay opacity-0"
                       id={`horizontal-radio-particulier-${index}`}
                       name={`associe-${index}`} value="particulier"
                       checked={associe['type'] === 'particulier'}
                       onChange={e => handleTypeAssocieChange(index, "particulier")} />
                        <div className="radio-circle">
                    <div className="radio-circle__outer-circle">
                        <div className="radio-circle__inner-circle"></div> 
                    </div>
                </div>  

                <div className="ml-2 mr-auto">
                  <div className="font-medium">Un particulier</div>
              </div>
               
              
            </div>

            <div  className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
                <input type="radio" className="overlay opacity-0"
                       id={`horizontal-radio-societe-${index}`}
                       name={`associe-${index}`} value="societe"
                       checked={associe['type'] === 'societe'}
                       onChange={e => handleTypeAssocieChange(index, "societe")} />
                      <div className="ml-2 mr-auto">
                  <div className="font-medium">Une société</div>
              </div>
               
               <div className="radio-circle">
                    <div className="radio-circle__outer-circle">
                        <div className="radio-circle__inner-circle"></div>
                    </div>
                </div> 
            </div>

                  
            </div>
               <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${(associe['type'] !== undefined && (associe['type'] === 'moi-meme' ||
               associe['type'] === 'particulier')) ? '' : 'hidden'}`}>
                   <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="my-2">Civilite</div>
                        <select type="text" value={associe["civilite"]} className="input w-full border flex-1 field"
                        onChange={e => handleInputChange(index, 'civilite', e.target.value)}>
                             <option value="monsieur">Monsieur</option>
                             <option value="madame">Madame</option>
                             <option value="mademoiselle">Mademoiselle</option>
                        </select>
                   </div>
                   {associe['type'] === 'particulier' ?
                       <>
                           <div className="intro-y col-span-12 sm:col-span-6">
                               <div className="mb-2">Nom</div>
                               <input type="text" value={associe["nom"]} className="input w-full border flex-1 field"
                                      placeholder="Nom" onChange={e => handleInputChange(
                                   index, 'nom', e.target.value)}/>
                           </div>
                           <div className="intro-y col-span-12 sm:col-span-6">
                               <div className="mb-2">Prénom(s)</div>
                               <input type="text" value={associe["prenoms"]}
                                      className="input w-full border flex-1 field"
                                      placeholder="Prénom(s)" onChange={e => handleInputChange(
                                   index, 'prenoms', e.target.value)}/>
                           </div>

                           <div className="intro-y col-span-12 sm:col-span-6">
                               <div className="my-2">Le numéro whatsapp :</div>

                               <PhoneInput
                                   country={"ci"}
                                   // value={this.e.target}
                                   value={associe["numero-telephone"]}
                                   onChange={(e) =>
                                       handleInputChange(index, 'numero-telephone', e)
                                   }
                                   autoFormat={false}
                                   inputStyle={{
                                       padding: "21px1 20px 21px 52px",
                                       width: "100%",
                                       boxShadow: "0px 3px 5px #00000007",
                                       borderColor: "rgba(226, 232, 240, 1)",
                                   }}
                                   placeholder="Numéro de téléphone"
                               />

{/*                               <input type="text" value={associe["numero-telephone"]}
                                      className="input w-full border flex-1 field" placeholder="Ex:0789707762"
                                      onChange={e => handleInputChange(
                                          index, 'numero-telephone', e.target.value)}/>*/}


                           </div>

                       </>
                       : null}
                   <div className="intro-y col-span-12 sm:col-span-6">
                       <div className="mb-2">Date de naissance</div>
                       <input type="date"
                              value={moment(associe['date-naissance']).format('YYYY-MM-DD')}
                              className="input w-full border flex-1 field"
                              placeholder="Date de naissance" onChange={e => handleInputChange(
                           index, 'date-naissance', e.target.value)}/>
                   </div>
                   <div className="intro-y col-span-12 sm:col-span-6">
                       <div className="mb-2">Ville de naissance</div>
                       <input type="text" value={associe["lieu-naissance"]} className="input w-full border flex-1 field"
                       placeholder="Ville de naissance" onChange={e => handleInputChange(
                           index, 'lieu-naissance', e.target.value)}/>
                   </div>
                   <div className="intro-y col-span-12 sm:col-span-6">
                       <div className="mb-2">Pays de naissance</div>
                       <input type="text" value={associe["pays-naissance"]} className="input w-full border flex-1 field"
                       placeholder="Pays de naissance" onChange={e => handleInputChange(
                           index, 'pays-naissance', e.target.value)}/>
                   </div>
                   <div className="intro-y col-span-12 sm:col-span-6">
                       <div className="mb-2">Nationalité</div>
                       <input type="text" value={associe["nationalite"]} className="input w-full border flex-1 field"
                       placeholder="Nationalité" onChange={e => handleInputChange(
                           index, 'nationalite', e.target.value)}/>
                   </div>
                   <div className="intro-y col-span-12 sm:col-span-6">
                       <div className="mb-2">Adresse</div>
                       <input type="text" value={associe["adresse"]} className="input w-full border flex-1 field"
                       placeholder="Adresse" onChange={e => handleInputChange(
                           index, 'adresse', e.target.value)}/>
                   </div>
                  <div className="col-span-12">Situation matrimoniale</div>
                   <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                       <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                           <input type="radio" className="input border mr-2 field"
                           id={`horizontal-radio-celibataire-${index}`}
                           name={`associe-${index}-situation-matrimoniale`} value="celibataire"
                           checked={associe['situation-matrimoniale'] === 'celibataire'}
                           onChange={e => handleInputChange(index, "situation-matrimoniale", "celibataire")}/>
                           <label className="cursor-pointer select-none"
                           htmlFor={`horizontal-radio-celibataire-${index}`}>
                               Célibataire
                           </label>
                       </div>
                       <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                           <input type="radio" className="input border mr-2 field"
                           id={`horizontal-radio-marie-${index}`}
                           name={`associe-${index}-situation-matrimoniale`} value="marie"
                           checked={associe['situation-matrimoniale'] === 'marie'}
                           onChange={e => handleInputChange(index, "situation-matrimoniale", "marie")}/>
                           <label className="cursor-pointer select-none"
                           htmlFor={`horizontal-radio-marie-${index}`}>
                               Marié-e
                           </label>
                       </div>
                   </div>
                   <div className={`intro-y col-span-12 ${(associe['situation-matrimoniale'] !== undefined &&
                   associe['situation-matrimoniale'] === 'marie') ? '' : 'hidden'}`}>
                       <div className="col-span-12  mb-4">Régime matrimonial</div>
                       <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                           <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                               <input type="radio" className="input border mr-2 field"
                               id={`horizontal-radio-communaute-de-bien-${index}`}
                               checked={associe['regime-matrimonial'] === 'Communaute de bien'}
                               name={`associe-${index}-regime-matrimonial`} value="Communaute de bien"

                               onChange={e => handleInputChange(index, "regime-matrimonial", "Communaute de bien")}/>
                               <label className="cursor-pointer select-none"
                               htmlFor={`horizontal-radio-communaute-de-bien-${index}`}>
                                   Communauté de bien
                               </label>
                           </div>
                           <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                               <input type="radio" className="input border mr-2 field"
                               id={`horizontal-radio-separation-de-bien-${index}`}
                               name={`associe-${index}-regime-matrimonial`} value="Séparation de bien"
                               checked={associe['regime-matrimonial'] === 'Séparation de bien'}
                               onChange={e => handleInputChange(index, "regime-matrimonial", "Séparation de bien")}/>
                               <label className="cursor-pointer select-none"
                               htmlFor={`horizontal-radio-separation-de-bien-${index}`}>
                                   Séparation de bien
                               </label>
                           </div>
                       </div>
                   </div>
                   <div className="intro-y col-span-12 sm:col-span-6">
                       <div className="mb-2">Combien {associe['type'] === 'moi-meme' ?
                       "apportez-vous" : "cet associé apporte"} à la société ? 
                       (Précisez la valeur du montant dans la monnaie locale du pays) ?</div>
                       <input type="number" value={associe["apport"]} className="input w-full border flex-1 field"
                       placeholder="Combien apportez-vous à la société ?" onChange={e => handleInputChange(
                           index, 'apport', e.target.value)}/>
                   </div>
               </div>
            <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${(associe['type'] !== undefined && associe['type'] === 'societe') ? '' : 'hidden'}`}>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Quel est le nom de la société</div>
                    <input type="text" value={associe["denomination"]} className="input w-full border flex-1 field"
                           placeholder="Quel est le nom de la société" onChange={e => handleInputChange(
                        index, 'denomination', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Quelle est sa forme sociale</div>
                    <input type="text" value={associe["forme-sociale"]} className="input w-full border flex-1 field"
                           placeholder="Quelle est sa forme sociale" onChange={e => handleInputChange(
                        index, 'forme-sociale', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Montant du capital social</div>
                    <input type="number" value={associe["capital-social"]} className="input w-full border flex-1 field"
                           placeholder="Montant du capital social" onChange={e => handleInputChange(
                        index, 'capital-social', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Adresse du siège social</div>
                    <input type="text" value={associe["siege-sociale"]} className="input w-full border flex-1 field"
                           placeholder="Adresse du siège social" onChange={e => handleInputChange(
                        index, 'siege-sociale', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Numéro RCCM</div>
                    <input type="text" value={associe["numero-sociale"]} className="input w-full border flex-1 field"
                           placeholder="Numéro RCCM" onChange={e => handleInputChange(
                        index, 'numero-sociale', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Ville d’immatriculation</div>
                    <input type="text" value={associe["ville-immatriculation"]}
                           className="input w-full border flex-1 field"
                           placeholder="Ville d’immatriculation" onChange={e => handleInputChange(
                        index, 'ville-immatriculation', e.target.value)}/>
                </div>
                <div className="col-span-12">Représentant Légal de la société associé</div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="my-2">Civilite</div>
                    <select type="text" value={associe["civilite-representant"]}
                            className="input w-full border flex-1 field"
                            onChange={e => handleInputChange(index, 'civilite-representant', e.target.value)}>
                        <option value="monsieur">Monsieur</option>
                        <option value="madame">Madame</option>
                        <option value="mademoiselle">Mademoiselle</option>
                    </select>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Nom</div>
                    <input type="text" value={associe["nom-representant"]} className="input w-full border flex-1 field"
                           placeholder="Nom" onChange={e => handleInputChange(
                        index, 'nom-representant', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Prénom</div>
                    <input type="text" value={associe["prenoms-representant"]}
                           className="input w-full border flex-1 field"
                           placeholder="Prénom" onChange={e => handleInputChange(
                        index, 'prenoms-representant', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="my-2">Le numéro whatsapp du représentant:</div>
                    <PhoneInput
                        country={"ci"}
                        // value={this.e.target}
                        value={associe["numero-telephone"]}
                        onChange={(e) =>
                            handleInputChange(index, 'numero-telephone', e)
                        }
                        autoFormat={false}
                        inputStyle={{
                            padding: "21px1 20px 21px 52px",
                            width: "100%",
                            boxShadow: "0px 3px 5px #00000007",
                            borderColor: "rgba(226, 232, 240, 1)",
                        }}
                        placeholder="Numéro de téléphone"
                    />

{/*                    <input type="text" value={associe["numero-telephone"]}
                           className="input w-full border flex-1 field" placeholder="Ex:0789707762"
                           onChange={e => handleInputChange(
                               index, 'numero-telephone', e.target.value)}/>*/}
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Combien cet associé apporte-t-il à la société
                        (Précisez la valeur du montant dans la monnaie locale du pays) ?
                    </div>
                    <input type="number" value={associe["apport"]} className="input w-full border flex-1 field"
                           placeholder="Combien apportez-vous à la société ?" onChange={e =>
                        handleInputChange(index, 'apport', e.target.value)}/>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="my-2">Adresse mail de l'associé:</div>
                    <input type="email" value={associe["email"]}
                           className="input w-full border flex-1 field" placeholder="Ex: vehnickgoueu@gmail.com"
                           onChange={e => handleInputChange(
                               index, 'email', e.target.value)}/>
                </div>


            </div>
        </div>
        </Accordion.Body>
      </Accordion.Item>

</Accordion>


          </div>


        );
      })}
        <div
            className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
        >
            <button
                className="button w-24 justify-center block bg-gray-200 text-gray-600
                dark:bg-dark-1 dark:text-gray-300"
                onClick={props.handleQuestionnaireBackClick ?? null}
            >
                Revenir
            </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
