import React, { useState } from "react";

function Sarl_sarlu_sasu_sas() {
  const [doc] = useState([
    {
      Personne_physique: {
        associe_piece: {
          lib_piece: "Liste des pièces à fournir",
          pieces:
            "La pièce d'identité  ( l'attestation; soit la nouvelle CNI + l'extrait de naissance, soit le passeport + l'extrait de naissance)",
          typePiece: ["Acte de mariage pour les mariés", "Casier judiciaire"],
        },
        autres: [
          "Une facture Cie ou SODECI du siège",
          "Un plan de Localisation fait à la main du siège ou Google Maps détaillé",
        ],
      },

      Personne_Morale: [
        "RCCM",
        "Statut de la personne morale",
        "Le PV de l’AG (autorisant la personne à participer à la création d’une société)",
      ],
    },
  ]);

  return (
    <div >
      {doc.map((doc, index) => (
        <div className="gap-4 text-sm" key={index}>
          <p className="">
            <strong>{doc.Personne_physique.associe_piece.lib_piece}</strong>
          </p>
          <h2 className="border-b-2 font-bold text-theme-1  my-3">
            Si l'associé est une Personne physique :
          </h2>

          <ul>
            <li className="ml-3">
              - {doc.Personne_physique.associe_piece.pieces}
            </li>

            {doc.Personne_physique.associe_piece.typePiece.map((piece, idx) => (
              <li className="ml-3" key={idx}>
                - {piece}
              </li>
            ))}
          </ul>

          <h2 className="border-b-2 font-bold text-theme-1 mt-4 mb-3">
            Si l'associé est une Personne Morale :
          </h2>
          <ul>
            {doc.Personne_Morale.map((item, idx) => (
              <li key={idx}>- {item}</li>
            ))}
          </ul>
          <h2 className="border-b-2 font-bold text-theme-1  my-3">Autres</h2>

          <ul>
            {doc.Personne_physique.autres.map((autre, idx) => (
              <li className="ml-3" key={idx}>
                - {autre}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Sarl_sarlu_sasu_sas;
