import { BN } from ".";
import { Components } from "../../..";
import { Utils } from "../../../../utils";

export function QuestionnaireBnSteps(props) {
  const champs_demandes = props?.data?.useDemande?.champs_demande
    ? JSON.parse(props?.data?.useDemande?.champs_demande)
    : "{}";
  // const hasFormule =
  //   champs_demandes["localisation-siege"] !== undefined &&
  //   champs_demandes["localisation-siege"].includes("Je domicilie chez Legafrik");
  const { type_demande } = props?.data?.useDemande;

  // const codePays=props?.data?.useDemande?.pays?.code;
  // const libelle=props?.data?.useDemande?.type_demande.replace("Créer une ", "");
  // const QuestionnaireFormByPays=Components.QuestionnairesForms[codePays];

  // const CODE_PAYS = props?.data?.useDemande.pays?.code ?? "";
  const LIBELLE_TYPE_DEMANDE = type_demande?.libelle
    ? type_demande.libelle.split(" ")[
        type_demande.libelle.split(" ").length - 1
      ]
    : "SARL";

  // const QuestionnaireFormByPays =
  //   Components.QuestionnairesForms[CODE_PAYS] ??
  //   Components.QuestionnairesForms.ND;
   const QuestionnaireFormsByPaysByService = BN
     ? BN[LIBELLE_TYPE_DEMANDE]
     : BN.SARL;

  
  

  if (LIBELLE_TYPE_DEMANDE === "SARL") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStep
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          />
        </Components.OptionalStep>

      
        <QuestionnaireFormsByPaysByService.Associes
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={7}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject} />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SARLU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStepSarlUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          />
        </Components.OptionalStep>

       
        <QuestionnaireFormsByPaysByService.AssocieTypesSarlUni
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.AssociesSarlUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSarlUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={7}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject} />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SASU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStepSasUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSasUni
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.AssociesSasUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject} />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SAS") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStepSas
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject} />
      </div>
    );
  }

  return <div>Pas de questionnaire</div>;
}
