export const SARLU = [
  {
    name: "denomination",
    question: "A quel nom avez-vous pensé pour votre entreprise ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "secteur-activite",
    question: "Quel est votre secteur d’activité ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Commerce général", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "Restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseil", price: "" },
      { value: "Cabinet de formation", price: "" },
      { value: "Société Transit", price: "" },
      { value: "Société Mines", price: "" },
      { value: "Centre Médical", price: "" },
      { value: "Aménagement Foncier", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "cabinet-formation",
    question: "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)", price: 100_000 },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  },
  
  {
    name: "cabinet-transit",
    question: "Souhaitez-vous d'être accompagné pour l'obtention du code import/export ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je me fais accompagner pour obtenir le code Import/Export (50 000 FCFA)", price: 50_000 },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  },
  {
    name: "transport",
    question:
      "Souhaitez-vous être accompagné par Legafrik pour vous immatriculer au Registre des transporteurs ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value:
          "Je me fais accompagner pour mon immatriculation (100 000 Fr CFA)",
        price: 100_000,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  },
  
  {
    name: "banque",
    question: "Dans quelle banque le compte sera-t-il ouvert ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Avez-vous déjà créé une entreprise ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-certificat",
    question:
      "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle commune se situe votre organisation ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Abidjan", price: 139_000 },
      { value: "Hors d'Abidjan", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      // { value: "Je domicilie chez Legafrik (30 000 FCFA/mois)", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Quel est le montant de votre capital social ?",
    description:
      "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
    type: "mutiple", //"mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "100 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "offre",
    question: "Quelle Offre choisissez-vous ?",
    type: "mutiple", //"mutiple",
    options: [
      { value: "À abidjan", price: 139_000 },
      { value: "À l'interieur du pays", price: 220_000 },
    ],
  },
  // {
  //   name: "gestion-domiciliation",
  //   question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
  //   description: "Choisissez une formule",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "6 mois à 180 000 FCFA(30 000 F/MOIS)", price: 180_000 },
  //     { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
  //     { value: "Je ne suis pas intéressé", price: "" },
  //   ],
  // },
  // {
  //   name: "gestion-dfe",
  //   question:
  //     "Souhaitez-vous que Legafrik s'occupe de votre formalité de rattachement fiscal et d'obtention de Déclaration Fiscale D'existence (DFE) ?",
  //   description:
  //     "Il est obligatoire d'effectuer une formalité de rattachement au centre d’impôt de votre entreprise dans les 10 jours suivant sa création sous peine d'une amende de 100 000 FCFA.",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "je confie à Légafrik avec rattachement DFE",
  //       price: 60_000,
  //     },
  //     // {
  //     //   value: "Je confie à Légafrik sans rattachement  DFE",
  //     //   price: 30_000,
  //     // },
  //     { value: "Je le gère moi-même", price: "" },
  //   ],
  // },
//   {
//     name: "gestion-graphique",
//     question:
//       "Avez-vous pensé à donner une image de marque à votre entreprise avec un logo et une charte graphique faits par des professionnels ?",
//     description: "",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "Je le confie à Légafrik (100 000 FCFA)", price: 100_000 },
//       { value: "Je ne suis pas intéressé", price: "" },
//     ],
//   },
//   {
//     name: "gestion-comptable",
//     question: "Souhaitez-vous bénéficier de notre solution d'accompagnement comptable ?",
//     description: "",
//     type: "mutiple", //"mutiple",
//     options : [
//         {value: "LEGAFRIK COMPTA (75 000/mois)", price: 225_000},
//         {value: "J'ai déjà un comptable", price: ""}
//     ]
// },
//   {
//     name: "gestion-facture-normalisees",
//     question:
//       "Souhaitez-vous que Legafrik s'occupe des formalités d'obtention de vos factures normalisées ?",
//     description:
//       "Toutes entreprises exerçant en en Côte d'Ivoire a l'obligation de délivrer des factures normalisées sous peine d'amende.",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "Lot de 50 factures normalisées à 65 000 FCFA", price: 65_000 },
//       { value: "Lot de 100 factures normalisées à 90 000 FCFA", price: 90_000 },
//       {
//         value: "Lot de 150 factures normalisées à 110 000 FCFA",
//         price: 110_000,
//       },
//       {
//         value: "Lot de 200 factures normalisées à 130 000 FCFA",
//         price: 130_000,
//       },
//       { value: "Je ne suis pas intéressé", price: "" },
//     ],
//   },
  {
    name: "solution-pay",
    question:
      "Souhaitez-vous bénéficier de notre solution d'accompagnement RH ?",
    description:
      "(Automatisation de la paie; rédaction contrat de travail; édition bulletin de paie; Déclaration rapide des salariés; gestion des absences et congés) ",
    type: "mutiple", //"mutiple",
    options: [
      { value: "LEGAFRIK PAY (75 000 Fr/Mois)", price: 225_000 },
      { value: "J'ai déja un RH", price: "" },
    ],
  },
];
