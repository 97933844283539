import { useEffect } from "react";
// import Alert from "react-bootstrap/Alert";
// import * as Icons from "react-feather";
import { Components } from "../../index";
import { Hooks } from "../../../hooks";

export function CartsStep(props) {
  // const handleInputChange = (name, value) => {};
  const useDemande = Hooks.useDemande();
  const usePaiement = Hooks.usePaiement();

  const handleSetAmount = (val) => {
    usePaiement.setMontant(val);
    useDemande.setMontant_total(val);
  };

  useEffect(() => {
    props.setPaymentAmount(usePaiement.montant);
  }, [usePaiement.montant]);
  return (
    <div
      className={`col-span-12 mt-8 ${
        props.step !== props.index ? "hidden" : ""
      }`}
    >
      <div className="intro-y box py-10 sm:py-20 mt-">
        <Components.Cart
          productList={props.champsDemandeData}
          selectedProductList={Object.keys(props.champs_demandes).map((key) => {
            return { [key]: props.champs_demandes[key] };
          })}
          handleRemoveProduct={null}
          demande={props.type_demande.libelle}
          //  handleBackClick={handleBackClick}
          // handleValidateClick={handleValidateClick}
          // isDisabled={useDemande.isDisabled}
          handleValidateClick={props.handleNextClick}
          setAmount={handleSetAmount}
          amount={usePaiement.montant}
          currency={props?.pays?.monnaie}
          handleBackClick={props.handleQuestionnaireBackClick ?? null}
          handleSubmit={props.handleSubmit}
        />
      </div>
    </div>
  );
}
