import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function AssocieTypes(props) {
  const formContainer = "associes-type-form-container";
  const nombreAssocies =
    props.nombreAssocies && Number.isInteger(parseInt(props.nombreAssocies))
      ? parseInt(props.nombreAssocies)
      : 0;
  const [associeTypes, setAssocieTypes] = useState(JSON.parse(props?.associeTypes).length>0?JSON.parse(props?.associeTypes)[0]:{});
  const [, setAssocieType] = useState("");
  // const [matrimonial, setMatrimonial] = useState("");

  const [, setChecked1] = useState(false);
  const [, setChecked2] = useState(false);

  const handleTypeAssocieChange = (name, value) => {
    setChecked1(false);
    setChecked2(false);

    const associesCopy = {};
    associesCopy["type"] = value;

    const formContainerElem = window.document.querySelector(
      `#associes-form-container-0`
    );
    Array.from(formContainerElem.querySelectorAll(".field")).forEach(
      (field) => (field.value = "")
    );

    setAssocieTypes(associesCopy);
    setAssocieType(value);
  };
  // const handleInputChange = (name, value) => {
  //   // setMatrimonial(value);
  //   // matrimonial=value;
  //   if (name == "situation-matrimoniale") {
  //     if (value === "celibataire") {
  //       setChecked1(true);
  //       setChecked2(false);
  //     } else {
  //       setChecked2(true);
  //       setChecked1(false);
  //     }
  //   }
  //   const associesCopy = { ...associeTypes };
  //   associesCopy[name] = value;

  //   setAssocieTypes(associesCopy);
  // };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "associeTypes", JSON.stringify([associeTypes]));
  };

  useEffect(() => {

    if (!nombreAssocies) return;
    // setAssocies(Array(nombreAssocies).fill({}))
  }, [nombreAssocies]);


  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}>
      <div className="intro-y col-span-12  ">
        <strong> Qui sont les associés de la société ?</strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            {" "}
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          Renseignez les informations personnelles de{" "}
          <Alert.Link href="#"> chaque associé </Alert.Link> (y compris
          vous-mêmes si vous êtes associé). Si vous n’avez pas certaines
          informations, vous pourrez compléter plus tard
        </Alert>
      </div>

      <div className="intro-y col-span-12" id={`${formContainer}-0`}>
        <div className="mb-2">ASSOCIÉ </div>
        <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
          <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
            <input
              type="radio"
              className="overlay opacity-0"
              id={`horizontal-radio-particulier-0`}
              name={`associe-0`}
              value="particulier"
              checked={associeTypes['type'] === 'particulier'}
              onChange={(e) =>
                handleTypeAssocieChange("associe_0", "particulier")
              }
            />

            <div className="ml-2 mr-auto">
              <div className="font-medium">Un particulier</div>
            </div>

            <div className="radio-circle">
              <div className="radio-circle__outer-circle">
                <div className="radio-circle__inner-circle"></div>
              </div>
            </div>
          </div>

          <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
            <input
              type="radio"
              className="overlay opacity-0"
              id={`horizontal-radio-societe-0`}
              name={`associe-0`}
              value="societe"
              checked={associeTypes['type'] === 'societe'}
              onChange={(e) => handleTypeAssocieChange("associe_0", "societe")}
            />
            <div className="ml-2 mr-auto">
              <div className="font-medium">Une société</div>
            </div>

            <div className="radio-circle">
              <div className="radio-circle__outer-circle">
                <div className="radio-circle__inner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
