import { useEffect, useState } from "react";
import { Utils } from "../../../utils";
import moment from "moment";
import PhoneInput from "react-phone-input-2";

export function PresidentStep(props) {
    const formContainer = "presidents-form-container";
    const associes = JSON.parse(props.associes);
    const [nombreGerant, ] = useState('');
    const [president, setPresident] = useState(props.president);
    const [presidentType, setPresidentType] = useState(Object.keys(props?.president).length === 0?'non':props?.president["fait-partie-des-associes"]!==""?"oui":"non");
    const handleInputChange = (name, value) => {
        let presidentCopy = {...president}
        
        if (name === "associe-president") {
            presidentCopy = {"associe-president": "oui", ...JSON.parse(value)};
        }else {
            presidentCopy['type'] = 'particulier';
            presidentCopy[name] = value;
        }
        
        setPresident(presidentCopy);
    }
    const handleTypePresidentChange = ( value) => {
        setPresident({});
        const formContainerElem = window.document.querySelector(
            `#${formContainer}-0`)
        Array.from(formContainerElem.querySelectorAll('.field'))
        .forEach(field => field.value = "");
        setPresidentType(value);
    }
    const handleStepSubmit = e => {
        e.preventDefault();

        if(president['date-piece-identite']==="" || president['date-piece-identite']===undefined){
            president['date-piece-identite']=moment().format('YYYY-MM-DD');
        }

        if(president["date-fin-validite-piece-identite"]==="" || president["date-fin-validite-piece-identite"]===undefined){
            president['date-fin-validite-piece-identite']=moment().format('YYYY-MM-DD');
        }

        if(president["date-naissance"]==="" || president["date-naissance"]===undefined){
            president['date-naissance']=moment().format('YYYY-MM-DD');
        }


        if (!president) return alert("Vous devez remplir le formulaire !");
         president['fait-partie-des-associes']=presidentType
         props.handleNextClick(e, 'presidents', JSON.stringify([president]));
    }

    useEffect(() => {
        if (!nombreGerant) return;
    }, [nombreGerant])

    
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <small className="intro-y col-span-12"> Indiquez les informations relatives au Président </small>
                <div className="intro-y col-span-12"  id={`${formContainer}-0`}>
                    <div className={`mb-2 `}>Le président fait-il partie des associés ? </div>
                    <div className="flex flex-col sm:flex-row mt-4 mb-2">
                        <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                            <input type="radio" className="input border mr-2" 
                            id={`horizontal-radio-oui-0`} 
                            name={`president-0`} value="oui"
                            checked={presidentType==='oui'}
                            onChange={e => handleTypePresidentChange("oui")}/> 
                            <label className="cursor-pointer select-none" 
                            htmlFor={`horizontal-radio-oui-0`}>
                                Oui
                            </label> 
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-gray-500 
                        mr-2 mt-2 sm:mt-0"> 
                            <input type="radio" className="input border mr-2" id={`horizontal-non-0`} 
                            name={`president-0`} value="non" checked={presidentType==='non'} onChange={e =>
                            handleTypePresidentChange("non")}/> 
                            <label className="cursor-pointer select-none" htmlFor={`horizontal-non-0`}>
                                Non
                            </label> 
                        </div>
                    </div>
                    <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                    ${(presidentType !== undefined && 
                    presidentType === 'oui') ? '' : 'hidden'}`}>
                            <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Lequel des associés est le président ?</div>
                            <select type="text" value={president['associe-president']} className="input w-full border flex-1 field"
                            onChange={e => handleInputChange( 'associe-president', e.target.value)}>
                                {associes.map((associe, index) => {
                                    return (
                                        <option value={JSON.stringify(associe)} key={index}>
                                            {Utils.Demande.getAssocieName(associe)}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Nom & prénom du père du président</div>
                            <input type="text" value={president['nom-pere']} className="input w-full border flex-1 field"
                            placeholder="Nom" onChange={e => handleInputChange('nom-pere', e.target.value)}/>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">nom & prénom de la mère du président</div>
                                <input type="text" value={president['nom-mere']} className="input w-full border flex-1 field"
                                placeholder="Prenom" onChange={e => handleInputChange(
                                    'nom-mere', e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                    ${(presidentType !== undefined && 
                    presidentType === 'non') ? '' : 'hidden'}`}>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">
                                Numéro de la carte d’identité
                                <small>(les anciennes pièces ne sont pas prises en compte )</small>
                            </div>
                            <input type="text" value={president['numero-piece-identite']} className="input w-full border flex-1 field"
                            placeholder="Numéro de la carte d’identité" onChange={e => handleInputChange(
                                'numero-piece-identite', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Type de la piece d'identité</div>
                            <select type="text" value={president['type-piece-identite']} className="input w-full border flex-1 field"
                            onChange={e => handleInputChange('type-piece-identite', e.target.value)}>
                                <option value="CNI">CNI</option>
                                <option value="Passeport">Passeport</option>
                                <option value="Attestation d'identité">Attestation d'identité</option>
                                <option value="Titre de séjour">Titre de séjour</option>
                                <option value="Carte de résident">Carte de résident</option>
                                <option value="Autres">Autres</option>
                            </select>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Pays de délivrance de la pièce</div>
                            <input type="text" value={president['pays-piece-identite']} className="input w-full border flex-1 field"
                            placeholder="Pays de délivrance de la pièce" onChange={e => handleInputChange(
                                'pays-piece-identite', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Date d’établissement de la pièce</div>
                            <input type="date"
                                    value={moment(president['date-piece-identite']).format('YYYY-MM-DD')} className="input w-full border flex-1 field"
                            placeholder="Date d’établissement de la pièce" onChange={e => handleInputChange(
                                'date-piece-identite', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Date de fin de validité de la pièce</div>
                            <input
                                type="date"
                                value={moment(president['date-fin-validite-piece-identite']).format('YYYY-MM-DD')}
                                className="input w-full border flex-1 field"
                                placeholder="Date de fin de validité de la pièce"
                                onChange={e => handleInputChange(
                                'date-fin-validite-piece-identite', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Civilite</div>
                            <select type="text" value={president['civilite']} className="input w-full border flex-1 field"
                            onChange={e => handleInputChange('civilite', e.target.value)}>
                                <option value="monsieur">Monsieur</option>
                                <option value="madame">Madame</option>
                                <option value="mademoiselle">Mademoiselle</option>
                            </select>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Nationalite</div>
                            <input type="text" value={president['nationalite']} className="input w-full border flex-1 field"
                            placeholder="Nationalite" onChange={e => handleInputChange(
                                'nationalite', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Adresse</div>
                            <input type="text" value={president['adresse']} className="input w-full border flex-1 field"
                            placeholder="Adresse" onChange={e => handleInputChange(
                                'adresse', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Nom</div>
                            <input type="text" value={president['nom']} className="input w-full border flex-1 field"
                            placeholder="Nom" onChange={e => handleInputChange(
                                    'nom', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Prenom</div>
                            <input type="text" value={president['prenoms']} className="input w-full border flex-1 field"
                            placeholder="Prenom" onChange={e => handleInputChange(
                                    'prenoms', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Numéro de téléphone</div>

                            <PhoneInput
                                country={"ci"}
                                // value={this.e.target}
                                value={president['numero-telephone']}
                                onChange={(e) =>
                                    handleInputChange( 'numero-telephone', e)
                                }
                                autoFormat={false}
                                inputStyle={{
                                    padding: "21px1 20px 21px 52px",
                                    width: "100%",
                                    boxShadow: "0px 3px 5px #00000007",
                                    borderColor: "rgba(226, 232, 240, 1)",
                                }}
                                placeholder="Numéro de téléphone"
                            />

{/*                            <input type="text" value={president['numero-telephone']} className="input w-full border flex-1 field"
                            placeholder="Numéro de téléphone" onChange={e => handleInputChange(
                                    'numero-telephone', e.target.value)}/>*/}

                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Date de naissance</div>
                            <input type="date" value={moment(president['date-naissance']).format('YYYY-MM-DD')} className="input w-full border flex-1 field"
                            placeholder="Date de naissance" onChange={e => handleInputChange(
                                    'date-naissance', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Lieu de naissance</div>
                            <input type="text" value={president['lieu-naissance']} className="input w-full border flex-1 field"
                            placeholder="Lieu de naissance" onChange={e => handleInputChange(
                                    'lieu-naissance', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Nom & Prénom(s) du père du président</div>
                            <input type="text" value={president['nom-pere']} className="input w-full border flex-1 field"
                            placeholder="Nom & Prénom(s) du père du président" onChange={e => 
                                handleInputChange('nom-pere', e.target.value)}/>
                        </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Nom & Prénom(s) de la mère du  président</div>
                            <input type="text" value={president['nom-mere']} className="input w-full border flex-1 field"
                            placeholder="Nom & Prénom(s) de la mère du président" 
                            onChange={e => handleInputChange('nom-mere', e.target.value)}/>
                        </div>
                    </div>
                </div>
        
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}