import React, { useState } from "react";

function Ong_fondation_association() {
  const [doc] = useState([
    {
      pieces:
        "La pièce d'identité du déclarant :( l'attestation; soit la nouvelle CNI + l'extrait de naissance, soit le passeport + l'extrait de naissance)",
      typePiece: [
        "Pièce d'identité du Président",
        "Pièce d'identité du secrétaire général",
        "Pièce d'identité du trésorier général",
        "Pièce d'identité du commissaire au compte",
        "Pièce d'identité du commissaire au compte adjoint",
      ],

      autres: [
        "Une facture Cie ou SODECI du siège",
        "Un plan de Localisation fait à la main du siège ou Google Maps détaillé",
      ],
    },
  ]);

  return (
    <div >
      <p className="text-2xl text-theme-1 border-b-2 mb-2">
        Liste des pièces à fournir :
      </p>

      {doc.map((doc, index) => (
        <div className="text-sm"  key={index}>
          <ul>
            <li className="ml-3 text-end"> - {doc.pieces}</li>
            {doc.typePiece.map((autre, idx) => (
              <li className="ml-3" key={idx}>
                - {autre}
              </li>
            ))}
            <h2 className="border-b-2 font-bold text-theme-1  my-5">Autres</h2>

            {doc.autres.map((autre, idx) => (
              <li className="ml-3" key={idx}>
                - {autre}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Ong_fondation_association;
