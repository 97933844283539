import { useState } from "react";
import moment from "moment";
import {transformSimpleNumberValueToStrings} from "../../../utils/utility";
export function Contrat(props) {
    const [infoEmploi, setInfoEmploi] = useState(props?.infoEmploi);
    const [employe, setEmploye] = useState(props?.employe);
    const [responsableEmploi, setResponsableEmploi] = useState(props?.responsableEmploi);
    const [typeEntreprise, ] = useState(props?.typeEntreprise);

    const handleInputChangeInfoEmploi = (name, value) => {
        let infoEmploiCopy = {...infoEmploi}
        infoEmploiCopy[name] = value;
        setInfoEmploi(infoEmploiCopy);
    }

    const handleInputChangeEmploye = (name, value) => {
        let employeCopy = {...employe}
        employeCopy[name] = value;
        setEmploye(employeCopy);
    }

    const handleInputChangeResponsableEmploi = (name, value) => {
        let responsableEmploiCopy = {...responsableEmploi}
        responsableEmploiCopy[name] = value;
        setResponsableEmploi(responsableEmploiCopy);
    }

    const handleStepSubmit = e => {
        e.preventDefault();

        let send={
            "info-emploi":transformSimpleNumberValueToStrings(infoEmploi),
            "responsable-emploi":transformSimpleNumberValueToStrings(responsableEmploi),
            "employe":transformSimpleNumberValueToStrings(employe)
        }
       props.handleNextClick(e, 'contrat', JSON.stringify(send));
    }

    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 ${props.step !== props.index ? 'hidden' : ''}`}>

            {/*Informations relatives à l'employeur*/}
            <div className="intro-y col-span-12 mt-5 mb-5">
                <strong>Informations relatives à l'employeur</strong>
            </div>

            {typeEntreprise==='particulier'?( <>
                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Civilité</div>
                    <select  value={responsableEmploi['civilite-maitre-emploi'] ?? ""} className="input w-full border flex-1 field"
                            onChange={e => handleInputChangeResponsableEmploi('civilite-maitre-emploi', e.target.value)}>
                        <option value={'monsieur'}>Monsieur</option>
                        <option value={'madame'}>Madame</option>
                        <option value={'mademoiselle'}>Mademoiselle</option>
                    </select>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Nom de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['nom-maitre-emploi'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Nom de l’employeur" onChange={e =>
                        handleInputChangeResponsableEmploi('nom-maitre-emploi', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Prénoms de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['prenoms-maitre-emploi'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Quartier" onChange={e =>
                        handleInputChangeResponsableEmploi('prenoms-maitre-emploi', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Civilité du tuteur</div>
                    <select value={responsableEmploi['civilite-tuteur'] ?? ""} className="input w-full border flex-1 field"
                            onChange={e => handleInputChangeResponsableEmploi('civilite-tuteur', e.target.value)}>
                        <option value={'monsieur'}>Monsieur</option>
                        <option value={'madame'}>Madame</option>
                        <option value={'mademoiselle'}>Mademoiselle</option>
                    </select>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Nom du tuteur</div>
                    <input type="text"
                           value={responsableEmploi['nom-tuteur'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Quartier" onChange={e =>
                        handleInputChangeResponsableEmploi('nom-tuteur', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Prénoms du tuteur</div>
                    <input type="text"
                           value={responsableEmploi['prenoms-tuteur'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Quartier" onChange={e =>
                        handleInputChangeResponsableEmploi('prenoms-tuteur', e.target.value)}/>
                </div>
            </>):null}

            {typeEntreprise==='entreprise'?(<>


                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Dénomination de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['denomination'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Dénomination de l’employeur" onChange={e =>
                        handleInputChangeResponsableEmploi('denomination', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Forme juridique de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['forme-juridique']?? ""}
                           className="input w-full border flex-1"
                           placeholder="Forme juridique de l’employeur" onChange={e =>
                        handleInputChangeResponsableEmploi('forme-juridique', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Capital social de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['capital-social'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Capital social de l’employeur" onChange={e =>
                        handleInputChangeResponsableEmploi('capital-social', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Ville d’immatriculation de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['ville'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Ville d’immatriculation de l’ imployeur" onChange={e =>
                        handleInputChangeResponsableEmploi('ville-immatriculation', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Numéro d’immatriculation de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['numero-immatriculation'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Numero d’immatriculation de l’employeur" onChange={e =>
                        handleInputChangeResponsableEmploi('numero-immatriculation', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Siège social de l’employeur</div>
                    <input type="text"
                           value={responsableEmploi['siege-social'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Siège social de l’employeur"
                           onChange={e => handleInputChangeResponsableEmploi('siege-social', e.target.value)}/>
                </div>


                <div className="intro-y col-span-12 sm:col-span-12">
                    <h2><strong className={`mb-2`}>représentant légal :</strong></h2>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Type du représentant légal</div>
                    <select value={responsableEmploi['type-representant'] ?? ""}  className="input w-full border flex-1 field"
                            onChange={e => handleInputChangeResponsableEmploi('type-representant', e.target.value)}>
                        <option value={'president'}>Président</option>
                        <option value={'directeur'}>Directeur général</option>
                        <option value={'gerant'}>Gérant</option>
                    </select>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Civilité représentant légal:</div>
                    <input type="text"
                           value={responsableEmploi['civilite-representant'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Civilité représentant légal"
                           onChange={e => handleInputChangeResponsableEmploi('civilite-representant', e.target.value)}/>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Nom & Prénom(s) représentant légal :</div>
                    <input type="text"
                           value={responsableEmploi['nom-representant'] ?? ""}
                           className="input w-full border flex-1"
                           placeholder="Noms & Prénoms représentant légal"
                           onChange={e => handleInputChangeResponsableEmploi('nom-representant', e.target.value)}/>
                </div>


            </>) : null}


            {/*Informations relatives à l'employé*/}
            <div className="intro-y col-span-12 mt-5">
                <strong>Informations relatives à l'employé</strong>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Civilité</div>
                <select value={employe['civilite'] ?? ""} className="input w-full border flex-1 field"
                        onChange={e => handleInputChangeEmploye('civilite', e.target.value)}>
                    <option value={'monsieur'}>Monsieur</option>
                    <option value={'madame'}>Madame</option>
                    <option value={'mademoiselle'}>Mademoiselle</option>
                </select>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom</div>
                <input type="text"
                       value={employe['nom'] ?? ""}
                       className="input w-full border flex-1" placeholder="Nom"
                       onChange={e => handleInputChangeEmploye('nom', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Prénom(s)</div>
                <input type="text"
                       value={employe['prenoms'] ?? ""}
                       className="input w-full border flex-1" placeholder="Prénom(s)"
                       onChange={e => handleInputChangeEmploye('prenoms', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Adresse</div>
                <input type="text"
                       value={employe['adresse'] ?? ""}
                       className="input w-full border flex-1" placeholder="Adresse"
                       onChange={e => handleInputChangeEmploye('adresse', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nationalité</div>
                <input type="text"
                       value={employe['nationalite'] ?? ""}
                       className="input w-full border flex-1" placeholder="Nationalité"
                       onChange={e => handleInputChangeEmploye('nationalite', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Date de naissance</div>
                <input type="date"
                       value={moment(employe['date-naissance']).format('YYYY-MM-DD')}
                       className="input w-full border flex-1 field" placeholder="Date de naissance"
                       onChange={e => handleInputChangeEmploye('date-naissance', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Lieu de naissance</div>
                <input type="text"
                       value={employe['lieu-naissance'] ?? ""}
                       className="input w-full border flex-1 field" placeholder="Lieu de naissance" onChange={e => handleInputChangeEmploye('lieu-naissance', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Pays de naissance</div>
                <input type="text"
                       value={employe['pays-naissance']??""}
                       className="input w-full border flex-1" placeholder="Pays de naissance" onChange={e => handleInputChangeEmploye('pays-naissance', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Type de la piece d'identité</div>
                <select
                    value={employe["type-piece-identite"]??""}
                    className="input w-full border flex-1 field" onChange={e => handleInputChangeEmploye('type-piece-identite', e.target.value)}>
                    <option value="CNI">CNI</option>
                    <option value="Passeport">Passeport</option>
                    <option value="Attestation d'identité">Attestation d'identité</option>
                    <option value="Titre de séjour">Titre de séjour</option>
                    <option value="Carte de résident">Carte de résident</option>
                    <option value="Autres">Autres</option>
                </select>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Date d'etablissement de la pièce</div>
                <input type="date" className="input w-full border flex-1 field"
                       value={moment(employe['date-piece-identite']).format('YYYY-MM-DD')}
                       placeholder="Date d’établissement de la pièce" onChange={e => handleInputChangeEmploye('date-piece-identite', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Date de validité de la pièce :</div>
                <input type="date"
                       value={moment(employe['date-fin-validite-piece-identite']).format('YYYY-MM-DD')}
                       className="input w-full border flex-1 field" placeholder="Date de fin de validité de la pièce" onChange={e => handleInputChangeEmploye('date-fin-validite-piece-identite', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de la pièce</div>
                <input type="text" className="input w-full border flex-1 field"
                       value={employe["numero-piece-identite"]??""}
                       placeholder="Numéro de la carte d’identité" onChange={e => handleInputChangeEmploye('numero-piece-identite', e.target.value)}/>
            </div>


            {/*Informations relatives aux modalités du contrat*/}
            <div className="intro-y col-span-12 mt-5 mb-5">
                <strong>Informations relatives aux modalités du contrat</strong>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Catégorie professionnelle</div>
                <input type="text"
                       value={infoEmploi['categorie-professionnel']??""}
                       className="input w-full border flex-1" placeholder="Catégorie professionnelle" onChange={e => handleInputChangeInfoEmploi('categorie-professionnel', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Grade de la catégorie</div>
                <input type="text"
                       value={infoEmploi['grade-categorie-professionnel']??""}
                       className="input w-full border flex-1" placeholder="Grade de la catégorie" onChange={e => handleInputChangeInfoEmploi('grade-categorie-professionnel', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Liste des taches et missions</div>
                <input type="text"
                       value={infoEmploi['missions']??""}
                       className="input w-full border flex-1" placeholder="Missions" onChange={e => handleInputChangeInfoEmploi('missions', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Date début contrat</div>
                <input type="date" className="input w-full border flex-1 field" placeholder="Date d’établissement de la pièce"
                       value={moment(infoEmploi['debut-emploi']).format('YYYY-MM-DD')}
                       onChange={e => handleInputChangeInfoEmploi('debut-emploi', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Date fin contrat</div>
                <input type="date" className="input w-full border flex-1 field"
                       value={moment(infoEmploi['fin-emploi']).format('YYYY-MM-DD')}
                       placeholder="Date fin contrat" onChange={e => handleInputChangeInfoEmploi('fin-emploi', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Durée période d'essai</div>
                <input type="text"
                       value={infoEmploi['duree-essaie']??""}
                       className="input w-full border flex-1" placeholder="Durée période d'essai" onChange={e => handleInputChangeInfoEmploi('duree-essaie', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Lieu de travail</div>
                <input type="text"
                       value={infoEmploi['lieu-de-emploi']??""}
                       className="input w-full border flex-1" placeholder="Lieu de travail" onChange={e => handleInputChangeInfoEmploi('lieu-de-emploi', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Temps de travail</div>
                <input type="text"
                       value={infoEmploi['duree-de-travail']??""}
                       className="input w-full border flex-1" placeholder="Temps de travail" onChange={e => handleInputChangeInfoEmploi('duree-de-travail', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Jours de travail</div>
                <input type="text"
                       value={infoEmploi['jours-de-travail']??""}
                       className="input w-full border flex-1" placeholder="Jours de travail" onChange={e => handleInputChangeInfoEmploi('jours-de-travail', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Montant du salaire</div>
                <input type="text"
                       value={infoEmploi['salaire-en-chiffre']??""}
                       className="input w-full border flex-1" placeholder="Montant du salaire" onChange={e => handleInputChangeInfoEmploi('salaire-en-chiffre', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Ville de signature</div>
                <input type="text"
                       value={infoEmploi['ville-signature']??""}
                       className="input w-full border flex-1" placeholder="Ville de signature" onChange={e => handleInputChangeInfoEmploi('ville-signature', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-theme-1
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}
